import React, { useState, useEffect } from "react";
import { Box, Text, Image, Flex, IconButton, Tooltip, Progress, Spacer } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CloseIcon } from "@chakra-ui/icons";
import { decrementTimer, hideNowBought } from "../../redux/homeSlice";

const NowBought = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { nowBought, nowBoughtTimer, isNowBoughtVisible } = useSelector((state) => state.home);

    const handleProductClick = (productId, sku_id) => {
        navigate(`/product/details/${productId}?sku_id=${sku_id}`, { target: "_blank" });
    };

    const handleClose = () => {
        dispatch(hideNowBought());
    };

    useEffect(() => {
        // Hide popup if not on the homepage
        if (location.pathname !== "/" && isNowBoughtVisible) {
            dispatch(hideNowBought());
        }
    
        // Set up the timer decrement if visible and on the homepage
        if (nowBoughtTimer > 0 && isNowBoughtVisible && location.pathname === "/") {
            const interval = setInterval(() => {
                dispatch(decrementTimer());
            }, 1000);
            return () => clearInterval(interval);
        }
    
        // Clear interval if timer reaches zero
        if (nowBoughtTimer === 0) {
            dispatch(hideNowBought());
        }
    }, [location.pathname, nowBoughtTimer, isNowBoughtVisible, dispatch]);
    

    if (location.pathname !== "/" || !isNowBoughtVisible || !nowBought) return null;

    return (
        <>
        <Box
            position="fixed"
            zIndex="1000"
            p="10px"
            bg="white"
            boxShadow="lg"
            borderRadius="md"
            border="1px solid #b1b2b3"
            maxW="300px"
            mx={{ base: "auto", md: "0" }}
            left={{ base: "0", md: "auto" }}
            right={{ base: "0", md: "100px" }}
            bottom={{ base: "65px", md: "40px" }}
        >
            <Flex alignItems="center" justifyContent="space-between">
                {nowBought?.images?.image && (
                    <Image
                        src={nowBought.images?.image}
                        alt={nowBought?.name}
                        boxSize="60px"
                        borderRadius="md"
                        border="gray 1px solid"
                        mr="10px"
                        cursor="pointer"
                        onClick={() => handleProductClick(nowBought.product_unique_id, nowBought.sku_id)}
                    />
                )}
                <Box flex="1" cursor="pointer" onClick={() => handleProductClick(nowBought.product_unique_id, nowBought.sku_id)}>
                    <Text fontWeight="bold" fontSize="md" noOfLines={1} color='rgb(244, 17, 6)'>
                        {nowBought?.title}
                    </Text>
                    <Tooltip label={nowBought?.name} fontSize="sm" placement="top" hasArrow>
                        <Text fontWeight="bold" fontSize="sm" noOfLines={1}>
                            {nowBought?.name}
                        </Text>
                    </Tooltip>
                    <Text fontSize="xs" color="gray.500">
                        Rating: {nowBought?.average_rating} ★
                    </Text>
                </Box>
                <Flex direction="column" alignItems="center">
                    <IconButton
                        size="sm"
                        icon={<CloseIcon />}
                        aria-label="Close"
                        variant="ghost"
                        onClick={handleClose}
                    />
                    <Spacer/>
                    <Text fontSize="xs" color="gray.500" textAlign='center'>
                        {nowBoughtTimer}s
                    </Text>
                </Flex>
            </Flex>
           
        <Progress
                value={(nowBoughtTimer / 25) * 100}
                size="xs"
                colorScheme="red"
                borderRadius="md"
                mt="4"
                isAnimated
                hasStripe
                />
                </Box>
        </>
    );
};

export default NowBought;
